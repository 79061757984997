/*
Theme Name: Quick Munch - FOOD DELIVERY & MULTIVENDOR HTML5 Template.
Author: Metropolitan Themes
Author URL: https://themeforest.net/user/metropolitantheme/portfolio
Version: 1.0.0
*/
@media (max-width: 1500px) {
	.location-picker {
		width: 100%;
	}
	.filters .delivery-slider .irs--flat .irs-grid-text {
		font-size: 14px;
	}
	.our-articles .side-bar .product-box .product-caption .title-box .tags,
	.home-4 .product-box .product-caption .title-box .tags {
		display: none;
	}

}
@media (max-width: 1200px) {
	.header .right-side .catring,
	.header .right-side .user-details,
	.header .right-side .user-details,
	.header .right-side .gem-points,
	.header .right-side .cart-btn {
		margin: 0 10px;
	}
	.filters .delivery-slider .irs--flat .irs-grid-text {
		font-size: 10px;
	}
	.product-list-view .product-list-details .product-detail-right-box {
		width: 70%;
	}
	.product-list-view .product-list-time {
		width: 31%;
	}
	.promocode-text,
	.product-list-view .product-list-time ul li {
		padding: 0 5px;
	}
	.banner-1 .content-wrapper .content-box .input-group .input-group2 input,
	.inner-wrapper .main-page .login-box input {
		border-radius: 4px 0 0 4px;
	}
	.banner-1 .content-wrapper .content-box .input-group .input-group2,
	.inner-wrapper .main-page .login-box .input-group .input-group2 {
		margin-bottom: 20px;
	}
	.banner-1 .content-wrapper .content-box .input-group-prepend,
	.inner-wrapper .main-page .login-box .input-group-prepend {
		display: flex;
	}
	.how-it-works .how-it-works-box.arrow-1:after{
		top: -20px;
	}
	.home-4 .product-box .product-caption .title-box .tags {
		display: block;
	}
	.our-blog .blog-box .post {
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.our-blog .blog-box .side-blog {
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.thankmsg-sec .msg-wrapper .contact-details>li {
		font-size: 12px;
		margin: 0 8px;
	}
	.side-blog .content-wrap .entry-title{
		font-size: 12px;
	}
	.mb-lg-20 {
		margin-bottom: 20px;
	}
	.mb-lg-40 {
		margin-bottom: 40px;
	}
}
@media (max-width: 992px) {
	.copyright .medewithlove{
	  margin-bottom: 20px;
	}
	.header .logo img {
		transform: none;
	}
	.product-list-view .product-list-time {
		width: 32%;
	}
	.side-blog .content-wrap .entry-title{
		font-size: 18px;
	}
	.header .logo {
		max-width: 115px;
	}
	.header .search-form span {
		font-size: 12px;
	}
	.filters .delivery-slider .irs--flat .irs-grid-text {
		font-size: 14px;
	}
	.header .gem-points i,
	.header .right-side .cart-btn a i {
		font-size: 25px;
	}
	.filter-sidebar {
		padding: 0 15px;
	}
	.product-list-view,
	.sort-tag-filter {
		padding: 15px 0;
	}
	.header .gem-points span {
		display: none;
	}
	.main-padding {
		padding: 64px;
	}
	.our-articles .blog-inner{
		margin-top: 0;
	}
	.promocode-text {
		width: 100%;
	}
	.how-it-works .how-it-works-box.arrow-1:after{
		top: -60px;
    	left: 80px;
	}
	.how-it-works .how-it-works-box.arrow-2:after{
		display: none;
	}
	.main-banner .footer-img {
		max-width: 170px;
	}
	.our-blog .blog-box .post .blog-img img,
	.our-blog .blog-box .post .blog-img {
		height: auto;
	}
	.our-articles .side-bar .product-box .product-caption .title-box .tags,
	.our-blog .blog-box {
		display: block;
	}
	.our-blog .blog-box .post,
	.our-blog .blog-box .side-blog {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.banner-2 {
		height: 1000px;
	}
	.thankmsg-sec .msg-wrapper .contact-details>li {
		font-size: 16px;
		margin: 0 20px;
	}
	.inner-wrapper .main-page .top-nav h5 {
	    margin-right: 15px;
	}
	.inner-wrapper .main-page .top-nav h5:last-child{
		margin-right: 0;
	}
	.mb-md-20 {
		margin-bottom: 20px;
	}
	.mb-md-40 {
		margin-bottom: 40px;
	}
	.copyright .payment-logo,
	.copyright .copyright-text,
	footer .ft-logo-box {
		justify-content: center;
	}
}
@media (max-width: 767px) {
	.header,
	.section-2,
	.main-banner {
		height: auto;
	}
	.header .search-form span {
		font-size: 14px;
	}
	.product-list-view .product-list-details .product-detail-right-box .product-list-tags,
	.product-list-view .product-list-details .product-detail-right-box .product-list-label,
	.header .right-side .cart-btn.notification-btn,
	.header .right-side .user-details>a>span,
	.heading .head-rating .product-review h6,
	.parent-megamenu a span,
	.main-search,
	.title-2 small,
	.banner-adv2,
	.banner-adv {
		display: none;
	}
	.header .main-search {
		flex: none;
	}
	.header .right-side .cart-btn.notification-btn,
	.header .right-side .user-details {
		position: static;
		margin: 0px;
	}
	.header .right-side .cart-btn.notification-btn>a {
		position: absolute;
		left: 50px;
		margin: 0 10px;
		top: 0;
	}
	.header .right-side .user-details>a {
		position: absolute;
		left: 0;
		margin: 0 10px;
		top: 0;
	}
	.header .user-details:hover>.user-dropdown {
		left: 0;
	}
	.parent-megamenu .megamenu .ex-collection-box{
		padding-bottom: 40px;
	}
	.heading .head-rating .product-review {
		width: 100%;
		justify-content: space-between;
		margin-left: 20px;
	}
	.heading .head-rating {
		text-align: center;
	}
	.header .logo {
		max-width: 100%;
		/* padding-left: 86px; */
	}
	.header .right-side .catring {
		margin: 0;
	}
	.header .right-side .catring>a {
		margin: 0;
		position: absolute;
		left: 60px;
		top: 18px;
	}
	.how-it-works .how-it-works-box.arrow-1:after{
		top: -35px;
    	left: 70px;
	}
	.parent-megamenu .megamenu{
		height: 100vh;
		overflow: auto;
	}
	.product-list-view .product-list-bottom .product-list-type,
	.product-list-view .product-list-details .product-list-title {
		padding-left: 0;
	}
	.product-list-view .product-list-info .product-list-img {
		flex: 0 0 75px;
		display: flex;
		align-items: center;
	}
	.sort-tag-filter,
	.product-list-view {
		padding: 15px 0px;
	}
	.restaurent-tags {
		margin-bottom: 15px;
	}
	.notification-btn .notification-dropdown {
		right: 0;
		left: -15%;
	}
	.notification-btn:hover>.notification-dropdown {
		left: 0;
	}
	.product-list-view,
	.location-picker,
	.user-page .login-sec,
	.inner-wrapper .main-page .top-nav {
		position: relative;
	}
	.user-page .login-sec {
		transform: none;
	}
	.main-sec {
		/* height: 190px; */
		height: 60px;
	}
	.sort-tag-filter {
		text-align: center;
	}
	.product-list-view {
		align-items: flex-start;
	}
	.map-gallery-sec .map-pl-0{
    	padding-left: 15px;
	}
	.megamenu,
	.cart-dropdown .cart-detail-box,
	.user-dropdown{
		opacity: 1;
	    visibility: visible;
	    left: 0;
	    display: none;
	}
	.megamenu.show,
	.cart-dropdown .cart-detail-box.show,
	.user-dropdown.show,
	.product-list-view .product-list-bottom,
	.product-list-view .product-list-details,
	.mobile-search,
	.restaurent-details-mob,
	.sort-tag-filter,
	.parent-megamenu>a>i {
		display: block;
	}
	.parent-megamenu>a.active>i:before{
		content: "\f00d";
	}
	.parent-megamenu i{
		font-size: 22px;
	}
	.product-list-view .product-right-col .product-list-bottom .mob-tags-label{
		display: flex;
		flex-wrap: wrap;
	}
	.product-list-view .product-right-col .product-list-bottom .mob-tags-label,
	.product-list-view .product-list-time ul,
	.promocode-text .promocode-btn {
		margin-top: 10px;
	}
	.product-list-view .product-list-time ul li:first-child {
		padding-left: 0;
	}
	.product-list-view .product-list-details .product-detail-right-box .product-list-rating .ratings,
	.product-list-view .product-list-info,
	.map-gallery-sec .map-pr-0 {
		padding-right: 15px;
	}
	.product-list-view .product-right-col {
		position: static;
	}
	.product-list-view .product-list-bottom .product-list-type span.new {
		position: absolute;
		left: 0;
		top: 63px;
		background: #fff;
		padding: 0 6px;
		border-radius: 3px;
		font-size: 12px;
		margin: 4px;
	}
	.product-list-view .product-list-details .product-detail-right-box .product-price-icon {
		position: absolute;
		left: 0;
		top: 100px;
	}
	.promocodeimg {
		/* height: 175px; */
		height: 40px;
	}
	.product-list-view .product-list-details .product-detail-right-box .product-list-rating {
		display: flex;
	}
	.product-list-view .product-list-details .product-detail-right-box .product-list-label button,
	.promocode-text .promocode-btn a {
		display: inline-block;
	}
	.product-list-view .product-list-time,
	.product-list-view .product-list-details .product-detail-right-box {
		width: 100%;
	}
	.product-list-view .product-list-time span {
		margin: 0;
		position: absolute;
		top: 15px;
		right: 0;
	}
	.checkout-product .img-name-value .product-name span,
	.tracking-sec,
	.promocode-text{
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.inner-wrapper .main-page .top-nav {
		top: 0;
		right: 0;
		align-items: center;
		justify-content: center;
	}
	.inner-wrapper .main-page .top-nav h5 {
		margin-right: 15px;
	}
	.main-padding {
		padding: 32px;
	}
	.mobile-search i {
		font-size: 25px;
	}
	.tracking-sec .tracking-map,
	.tracking-sec .tracking-details {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.promocode-text {
		padding: 30px;
	}
	.driver-tip-sec .tip-percentage {
		height: 65px;
	}
	.restaurent-product-list .restaurent-product-detail{
		flex-direction: column-reverse;
	}
	.restaurent-product-list .restaurent-product-img {
	    margin: 15px auto;
	    height: 150px;
	}
	.map-gallery-sec .main-box #locmap {
		height: 250px;
	}
	.tracking-sec .tracking-details .fullpageview{
		position: relative;
		bottom: 0;
		margin-top: 30px;
	}
	.color-theme{
		top:50%;
	}
	.custom-builder-panel{
		top: 36%;
	}
	/*side-panel*/
	#side-panel{
		display: none;
	}

	.mb-sm-20 {
		margin-bottom: 20px;
	}
	.mb-sm-40 {
		margin-bottom: 40px;
	}
}
@media (max-width: 576px) {
	.cart-dropdown .cart-detail-box,
	.notification-btn .notification-dropdown,
	.user-dropdown,
	.cart-dropdown:hover>.cart-detail-box,
	.notification-btn:hover>.notification-dropdown {
		width: 100%;
	}
	.user-dropdown,
	.cart-dropdown .cart-detail-box{
		height: 100vh;
	}
	.delivery-slider .irs--flat.irs-with-grid {
		width: 97%;
	}
	.filter-sidebar,
	.most-popular .browse-cat>div {
		padding: 0;
	}
	.user-page .login-sec .login-box {
		padding: 20px;
	}
	.header .user-details:hover>.user-dropdown {
		left: 0;
	}
	.how-it-works .how-it-works-box.arrow-1:after,
	.restaurent-logo {
		display: none;
	}
	.header .right-side .user-details>a,
	.header .right-side .cart-btn.notification-btn>a {
		margin: 0 5px;
	}
	.header .right-side .cart-btn.notification-btn>a {
		left: 40px;
	}
	.header .right-side .catring>a {
		left: 50px;
	}
	.user-dropdown ul>li {
		width: 33%;
	}
	.checkout-product .img-name-value .product-value {
		margin: 0 15px;
	}
	.checkout-product .img-name-value .product-img {
		width: 70px;
		height: 70px;
	}
	.restaurent-product-list .restaurent-product-detail {
		padding-right: 10px;
		padding-bottom: 0;
	}
	.restaurent-product-list .restaurent-product-detail .restaurent-tags {
		margin-bottom: 5px;
	}
	.recipt-sec .recipt-name.title,
	.restaurent-product-list>div,
	.recipt-sec p.title span,
	.recipt-sec p.title {
		display: block;
	}
	.restaurent-ad .ad-img .content-box {
		padding: 0 30px;
	}
	.banner-1 .right-side-content h1 {
		font-size: 45px;
	}
	.countdown-box .time-box:first-child {
		margin-left: 0;
	}
	.browse-cat .categories .icon {
		width: 125px;
	}
	.browse-cat-2 .categories .icon {
		width: auto;
	}
	.final-order .title2 span {
		margin-left: 80px;
	}
	.payment-option-tab>.nav>.nav-item {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.driver-tip-sec>.nav>.nav-item>.nav-link,
	.payment-option-tab>.nav>.nav-item>.nav-link {
		border: 1px solid rgba(67, 41, 163, .2);
	}
	.copyright .payment-logo {
		display: block;
		text-align: center;
	}
	.mb-xs-20 {
		margin-bottom: 20px;
	}
	.mb-xs-40 {
		margin-bottom: 40px;
	}
}

@media (max-width:386px){
	.product-box-3 .product-caption ul.food-description li:last-child{
		margin-top: 7px;
	}
}
