.modal-entire-screen{
  height:12vh;
  width:100vw;
  background-color: rgba(0,0,0,.5); /* background still visible*/
  z-index: 1000;
  position:fixed;
  bottom:4vh;
}

.consent-modal-div{
  
  color: white;
  font-weight: 700;

  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:1vw;

}

.consent-modal-div>*{

  margin: 0 1.25vw;
}

.consent-modal-text-div{
  position:relative !important;
  display: flex;
  flex-direction: row;

  margin-top:1.5vh;
  
}

.consent-modal-text-div>p{
  align-self: center;
  font-size: 1vw;
}

.consent-modal-text-div>a{
  font-weight: inherit;
  text-decoration: none;
  margin:0.4vh .4vw 0 ;
  color:red;
  font-size:0.8vw;
}

.consent-modal-text-div>a:hover{
  color:yellow;
}

.consent-modal-btn-div{
  position:relative !important;

  align-items: center;
  justify-content: center;
  margin-top:.5vh;
}

.consent-modal-btn-div>button{
  background-color: red;
  color:white;
  border-radius: .5vw;
  padding:5px;
  font-size: 1.25vw;
}

@media only screen and (max-width: 1024px) and (min-width: 280px) {
  
  .modal-entire-screen{
    height:12vh;
    width:100vw;
    background-color:rgba(0,0,0,.5); /* background still visible*/
    z-index: 1000;
    position:fixed;
    bottom:3vh;
  }

  .consent-modal-text-div{
    position:relative !important;
    display: flex;
    flex-direction: column;
    margin-top:1.5vh;
  }

  .consent-modal-text-div>p{
    align-self: center;
    font-size: 3.2vw;
  }

  .consent-modal-text-div>a{
    font-weight: inherit;
    text-decoration: none;
    margin-bottom:.5vh;
    color:yellow;
    font-size:0.8vw;
    font-size: 2.5vw;
  }

  .consent-modal-btn-div{
    position:relative !important;
  
    align-items: center;
    justify-content: center;
    margin-top:.5vh;
  }

  .consent-modal-btn-div>button{
    background-color:yellow;
    color:black;
    border-radius:.5vw;
    font-size: 4vw;
    width:25vw;
    height:7vh;
  }

}